import React from "react"
import {Container} from 'react-bootstrap'
import Layout from "../../components/Layout/Layout"
import HeroBanner from "../../components/HeroBanner/HeroBanner"
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import Share from "../../components/Share/Share"
import "./blog.css"

const CoreWebVitalsBlogPage = () => {
  const schemaMarkup =
  {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://www.guidingdigital.com/blog/core-web-vitals/"
      },
      "headline": "What You Need to Know About the Core Web Vitals Update",
      "image": [
          "https://www.guidingdigital.com/images/blog/1x1/core-web-vitals.png",
          "https://www.guidingdigital.com/images/blog/4x3/core-web-vitals.png",
          "https://www.guidingdigital.com/images/blog/16x9/core-web-vitals.png"
          ],  
      "datePublished": "2021-05-13T14:45:00-5:00",
      "dateModified": "2021-05-13T14:45:00-5:00",
      "author": {
          "@type": "Person",
          "name": "Philip Sparks"
      },  
      "publisher": {
          "@type": "Organization",
          "@id": "https://www.guidingdigital.com/",
          "name": "Guiding Digital",
          "logo": {
            "@type": "ImageObject",
            "url": "https://www.guidingdigital.com/images/logo.png"
          }
      },
      "description": "Google is releasing their page experience update to their search engine ranking system which includes core web vitals metrics.  Learn more about core web vitals and how you can prepare for the update."
  }

  return (
  <Layout
    title="Google Core Web Vitals Update | Guiding Digital"
    description="Google is releasing their page experience update to their search engine ranking system which includes core web vitals metrics.  Learn more about core web vitals and how you can prepare for the update." 
    schemaMarkup={schemaMarkup}
    metaUrl="https://www.guidingdigital.com/blog/core-web-vitals/"
    twitterCardType="summary_large_image"
    twitterImage="https://www.guidingdigital.com/images/blog/2x1/core-web-vitals.png">    
    <HeroBanner
      headline="Core Web Vitals"
      className="coreWebVitalsPage"
    />

    <Container className="blog-post">
      <div className="d-flex mb-1">    
        <div className="p-0 mt-3 align-self-center">
            <span>May 13, 2021</span>
        </div>
        <div className="ml-auto align-self-center">
          <span className="d-none d-sm-inline">Share: </span>
          <Share
            socialConfig={{
                twitterHandle: "GuidingDigital",
                config: {
                    url: `https://www.guidingdigital.com/blog/core-web-vitals/`,
                    title: "What You Need to Know About the Core Web Vitals Update",
                },
            }}
          />
        </div>
      </div>
        <p>
          Google is releasing an update to Google search called the page experience 
          update, and included in this update are the new core web vitals ranking signals.  
          In this article, I'll give you an overview of core web vitals, let you know 
          when to expect the update, cover why core web vitals should matter to you, 
          and discuss the next steps you should take to prepare for the update.
        </p>

        <h2>What are Core Web Vitals?</h2>
        <p>
          Core Web Vitals are performance focused metrics defined by Google that 
          indicate whether the page provides a good user experience.  They are a subset 
          of what Google is now referring to as page experience signals.  Page experience 
          signals are focused on user centric metrics that would indicate a good user 
          experience on a web page.  With this page experience update, Google is 
          combining existing ranking signals and the new core web vitals metrics into 
          page experience ranking signals.
        </p>
        <p>  
          The existing ranking signals that they are now grouping into the page experience 
          ranking signals are:
        </p>
        <ul>
          <li>
            <strong>Mobile Friendly</strong> - Indicates if a page provides a good experience 
            to users on a mobile device.
          </li>
          <li>
            <strong>Safe Browsing</strong> - Whether or not the site contains malicious or 
            deceptive content.
          </li>
          <li>
            <strong>HTTPS</strong> - Whether a site is served over https.
          </li>
          <li>
            <strong>No Intrusive Interstitials</strong> - whether or not you are 
            using ads or other pop-ups in a way that are not disruptive to a good user 
            experience.
          </li>
        </ul>

        <p>
          These first 4 are already ranking signals but are now grouped with the 
          new Core web Vitals metrics into a broader group of signals called page 
          experience signals.
        </p>
        <p>
          Currently the new core web vitals consist of 3 metrics:
        </p>
        <ul>
          <li>
            <strong>Largest Contentful Paint (LCP)</strong> - Largest Contentful Paint 
            measures loading performance.  LCP measures the time it takes to display the 
            largest image or block of text that will be visible within the user's viewport.  To be categorized as a good user experience, sites should target a largest contentful paint of 2.5 seconds or less.
          </li>
          <li>
            <strong>First Input Delay (FID)</strong> - FID, measures interactivity.  
            FID measures the time from when the user interacts with the page for the 
            first time to the time when the browser is able to process the response.  
            A good response time according to Google is less than 100 milliseconds.
          </li>
          <li>
            <strong>Cumulative Layout Shift (CLS)</strong> - CLS measures visual 
            stability.  Have you ever opened a web page and just as you try to click 
            on a link or menu item the page shifts and now you've opened an ad that 
            you didn't want to open or the wrong link.  This frustrating experience is 
            what cumulative layout shift is trying to discourage.  CLS is measured by 
            the sum of all layout shift scores.  In a future video, I'll go deeper 
            into this calculation, but for now just know that each time an element 
            moves after it is initially displayed to the user, a layout shift score 
            is calculated and then all of the scores are added up to measure CLS.  A 
            CLS less than 0.1 is considered a good CLS score.
          </li>
        </ul>
        
        <StaticImage
            src="../../images/blog/page-experience-signal.jpeg"
            alt="Page Experience Signals"
            layout="fullWidth" />
        
        <h2>When is this update going to rollout?</h2>
        <p>
          Originally, Google had announced that the page experience update that would 
          include core web vitals would be released in May, 2021.  However, in 
          April, they announced that that they would be delaying the start of the rollout 
          until mid-June, 2021.  Also, it will be a gradual rollout and they 
          state that it won't play its full role in their ranking systems until the 
          end of August.
        </p>
        <h2>Why does it matter and how will it affect my site?</h2>
        <p>
          Google has stated that the page experience updates are one of many factors 
          that they take into account for search rankings and that in general, you 
          shouldn't expect drastic changes to your rankings as a result of these 
          updates, and so if you have a site that has good relevant content, has good 
          on-page SEO practices, and is a healthy site in regard to SEO, then according 
          to Google, you shouldn't expect major changes to your search rankings 
          regardless of how your site is performing in regard to the core web vitals.
        </p>
        <p>
          The one exception to this is if you are in a competitive environment.  
          If your site and your competitors’ sites are both optimized or at the same 
          level of optimization, then how your site performs against the core web 
          vitals metrics might serve as a tie breaker between you and your competitors.
        </p>
        <p>
          Even if you are not in a competitive environment, I would strongly recommend 
          focusing on the core web vitals metrics.  The reason Google has selected 
          these metrics, is because they believe that these metrics are a good indication of a good user experience.  And so even if your site is currently ranking high, by focusing on these metrics you'll be ensuring a good user experience which can lead to higher conversion rates.
        </p>
        <h2>What should I do to prepare?</h2>  
        <ol>
          <li>
            <strong>Know Your Scores</strong> - The first thing I would recommend is 
            to make sure you know where your site currently stands against the core 
            web vitals metrics.  There are several ways you review your scores.  
            One way is through 
            the <a href="https://search.google.com/search-console" target="_blank" rel="noreferrer">Google Search console's</a> new 
            core web vitals report. 
            This report doesn't tell you the exact scores for each metric, but it tells 
            you if the pages on your site have passing scores according to Google's 
            standards.  If you find that you have URLs that need improvement, you can 
            then dig deeper into those pages using other tools.  
            Other tools that will help you measure your core web vitals are:

            <ul>
              <li>Light House audits that can be run from Chrome's developer tools.</li>
              <li>The <a href="https://developers.google.com/speed/pagespeed/insights/" target="_blank" rel="noreferrer">Page Speed Insights website</a>.</li>
              <li>Other paid SEO tools such as <a href="https://www.semrush.com/" target="_blank" rel="noreferrer">SEMrush</a>.</li>
            </ul>
          </li>
          <li>
              <strong>Learn About the Individual Metrics</strong> - After you've learned 
              where you stand with the Core Web Vitals, the next thing I would recommend 
              doing is to learn more about each metric and how each one is measured so 
              you can learn how to improve your scores.  Google has their web.dev site 
              where they go into more detail on each score.  
          </li>
        </ol>        

        <p>
          If you questions or need additional help in making sure you are ready for Google's 
          upcoming core web vitals update, feel free to contact me  
          through my social channels or <Link to="/contact/">contact page</Link> and 
          I'd be happy to talk about how Guiding Digital can help.
      </p>

      <h2>Additional Resources:</h2>
      <ul>
        <li><a href="https://developers.google.com/search/blog/2021/04/more-details-page-experience" target="_blank" rel="noreferrer">Google Search Central Blog - April 2021 Update</a></li>
        <li><a href="https://developers.google.com/search/docs/guides/page-experience" target="_blank" rel="noreferrer">Google Search Central - Understanding page experience in Google Search results</a></li>
        <li><a href="https://web.dev/vitals/" target="_blank" rel="noreferrer">web.dev - Web Vitals</a></li>
        <li><a href="https://support.google.com/webmasters/thread/104436075" target="_blank" rel="noreferrer">Core Web Vitals &amp; Page Experience FAQs</a></li>
      </ul>
    </Container>
  </Layout>
  )
}

export default CoreWebVitalsBlogPage